<template>
  <section>
    <section class="top">
      <div>
        <p class="tt_title">
          TimeTable
        </p>
        <p class="tt_subtitle">
          List Of TimeTables
        </p>
      </div>
      <div class="top-btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="timetable/new"
        >
          New TimeTable
        </VBtn>
      </div>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_card elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
    <section>
      <div class="content">
        <div class="grid">
          <div
            v-for="tt in timetables"
            :key="tt.id"
          >
            <VCard
              class="tt_card shadow"
              ripple
              @click="viewTimetable(tt.id)"
            >
              <div class="tt_card_content">
                <p class="tt_txt">
                  {{ tt.data.class }}
                </p>
                <p class="tt_txt_sub">
                  Class
                </p>
              </div>
            </VCard>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    timetables: [],
  }),
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/timetable/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.timetables = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    viewTimetable(v) {
      setTimeout(() => {
        this.$router.push(`/timetable/${v}`);
      }, 150);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/TimeTable/index';
</style>
